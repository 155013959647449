@font-face {
	font-family: 'ProximaNova';
	src: url('./fonts/ProximaNova-Regular.eot');
	src: url('./fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
		url('./fonts/ProximaNova-Regular.woff') format('woff'),
		url('./fonts/ProximaNova-Regular.ttf') format('truetype'),
		url('./fonts/ProximaNova-Regular.svg#ProximaNova_Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'ProximaNova';
	src: url('./fonts/ProximaNova-Regular-Italic.eot');
	src: url('./fonts/ProximaNova-Regular-Italic.eot?#iefix') format('embedded-opentype'),
		url('./fonts/ProximaNova-Regular-Italic.woff') format('woff'),
		url('./fonts/ProximaNova-Regular-Italic.ttf') format('truetype'),
		url('./fonts/ProximaNova-Regular-Italic.svg#ProximaNova_Regular_Italic') format('svg');
	font-weight: normal;
	font-style: oblique;
}
@font-face {
	font-family: 'ProximaNova';
	src: url('./fonts/ProximaNova-SemiBold.eot');
	src: url('./fonts/ProximaNova-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('./fonts/ProximaNova-SemiBold.woff') format('woff'),
		url('./fonts/ProximaNova-SemiBold.ttf') format('truetype'),
		url('./fonts/ProximaNova-SemiBold.svg#ProximaNova_SemiBold') format('svg');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'ProximaNova';
	src: url('./fonts/ProximaNova-SemiBold-Italic.eot');
	src: url('./fonts/ProximaNova-SemiBold-Italic.eot?#iefix') format('embedded-opentype'),
		url('./fonts/ProximaNova-SemiBold-Italic.woff') format('woff'),
		url('./fonts/ProximaNova-SemiBold-Italic.ttf') format('truetype'),
		url('./fonts/ProximaNova-SemiBold-Italic.svg#ProximaNova_SemiBold_Italic') format('svg');
	font-weight: 500;
	font-style: oblique;
}
@font-face {
	font-family: 'ProximaNova';
	src: url('./fonts/ProximaNova-Bold.eot');
	src: url('./fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
		url('./fonts/ProximaNova-Bold.woff') format('woff'),
		url('./fonts/ProximaNova-Bold.ttf') format('truetype'),
		url('./fonts/ProximaNova-Bold.svg#ProximaNova_Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: 'ProximaNova';
	src: url('./fonts/ProximaNova-Bold-Italic.eot');
	src: url('./fonts/ProximaNova-Bold-Italic.eot?#iefix') format('embedded-opentype'),
		url('./fonts/ProximaNova-Bold-Italic.woff') format('woff'),
		url('./fonts/ProximaNova-Bold-Italic.ttf') format('truetype'),
		url('./fonts/ProximaNova-Bold-Italic.svg#ProximaNova_Bold_Italic') format('svg');
	font-weight: bold;
	font-style: oblique;
}
